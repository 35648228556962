<template>
  <li v-if="showicon">
    <span>
      <el-tooltip class="item" :effect="stateradioTheme == '0' ? 'light' : 'dark'" content="公告" placement="bottom">
        <div class="newsicon">
          <a :href="news && news.detailUrl" target="_blank"> <i :style="{ color: stateradioTheme == '0' ? '#525355' : '#e4e7ed' }" class="el-icon-bell"/></a>
          <i v-if="news && news.isShow && news.detailUrl" class="dot"></i>
        </div>
      </el-tooltip>
    </span>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import BI_config from '@/utils/config'
export default {
  name: 'newsicon',
  props: ['radioTheme'],
  data() {
    return {
      stateradioTheme: '1',
      mpnewslistUrl: BI_config.mpmanage + '/News/IndexList',
      showicon: false,
    }
  },
  created() {
    this.stateradioTheme = this.radioTheme
    this.showicon = this.$EnvIsMallcoo
  },
  methods: {},
  computed: {
    ...mapState({
      news: state => state.news,
    }),
  },
}
</script>
<style lang="scss" scoped>
.newsicon {
  align-items: center;
  display: flex;
  font-size: 18px;

  .dot {
    position: absolute;
    margin-left: 13px;
    top: 20px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: red;
  }
}
</style>
