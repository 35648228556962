<template>
  <div class="headerBox dis_flex">
    <div class="logo">
      <a class="dis_flex_center" :href="projectlisturl()" target="_self">
        <img v-if="system == 'me'" src="~@/assets/images/melogo.png" alt="" />
        <img v-if="['sale', 'crm', 'bi'].includes(system)" src="~@/assets/images/eyelogo.png" alt="" />
      </a>
    </div>
    <div class="logo-r dis_flex">
      <div class="name dis_flex">
        <span :class="headdata.closeLeft ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="openLeft"></span>
        <h3 v-if="basicdata && basicdata.project" class="wordellipsis changespace">
          {{ basicdata.project.name }}
          <el-popover
            v-if="basicdata.project && basicdata.project.updateDate"
            popper-class="wenhao-pop"
            placement="right-start"
            title=""
            width="300"
            trigger="click"
          >
            <div class="project_info">{{ '数据更新时间: ' + basicdata.project.updateDate }}</div>
            <div v-if="maintInfo" class="project_info">{{ '维保到期时间: ' + maintInfo.endTime }}</div>
            <div v-if="maintInfo" class="project_info project_info_status">{{ '维保状态: ' + maintInfo.maintStatusDesc }}</div>
            <i class="wenhao iconfont iconshizhong" slot="reference"></i>
          </el-popover>
        </h3>
      </div>
      <div class="nav">
        <el-menu class="el-menu-default" mode="horizontal">
          <el-menu-item :index="index + 1 + ''" v-for="(item, index) in headdata.menus" :class="{ selected: activeIndex == index }" :key="index">
            <span @click="menuhref(item.href)">{{ item.title }}</span>
          </el-menu-item>
        </el-menu>

        <el-menu class="el-menu-custom custom" mode="horizontal" v-if="headdata.custom">
          <el-submenu index="5" popper-class="thmemCustomMenu">
            <template slot="title">{{ headdata.custom.title }}</template>
            <el-menu-item :index="'5' + '-' + childidx + 1 + ''" v-for="(child, childidx) in headdata.custom.children" :key="childidx">
              <template slot="title">{{ child.title }}</template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="head-r">
        <ul class="dis_flex" v-if="['bi', 'me'].includes(system)">
          <li v-if="system == 'bi'">
            <el-tooltip class="item" :effect="radioTheme == '0' ? 'light' : 'dark'" content="一键搜索" placement="bottom">
              <i @click="searchUser" class="iconfont iconchaxun"></i>
            </el-tooltip>
          </li>
          <NewsIcon :key="newsIconkey" :radioTheme="radioTheme" v-if="system == 'bi'" />
          <li v-if="system == 'bi'">
            <el-tooltip class="item" :effect="radioTheme == '0' ? 'light' : 'dark'" content="帮助中心" placement="bottom">
              <i @click="openHelp" class="iconfont iconwenhao4"></i>
            </el-tooltip>
          </li>
          <li v-if="system == 'bi'">
            <el-dropdown>
              <span class="el-dropdown-link">
                <!-- 基础管理 -->
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                <i class="iconfont iconguanli"></i>
              </span>
              <el-dropdown-menu class="thmemBottomMenu" slot="dropdown">
                <el-dropdown-item v-if="$commonJs._point('后台_权限管理_用户管理')">
                  <a :href="bigmanageurl1" target="_blank">用户管理</a>
                </el-dropdown-item>
                <el-dropdown-item v-if="$commonJs._point('后台_权限管理_角色管理')">
                  <a :href="bigmanageurl2" target="_blank">角色管理</a>
                </el-dropdown-item>
                <el-dropdown-item v-if="$commonJs._point('BI_M0108') && $EnvIsMallcoo">
                  <a :href="subscribeurl" target="_blank">订阅管理</a>
                </el-dropdown-item>
                <el-dropdown-item v-if="$commonJs._point('BI_M0111') && $EnvIsMallcoo">
                  <a :href="earlywarnurl" target="_blank">预警管理</a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a :href="bigmanageurl3" target="_blank">下载中心</a>
                </el-dropdown-item>
                <!-- <el-dropdown-item v-if="$EnvIsMallcoo">
                  <a :href="bigmanageurl4" target="_blank">预警中心</a>
                </el-dropdown-item> -->
                <el-dropdown-item v-if="$EnvIsMallcoo">
                  <a :href="bigmanageurl5" target="_blank">操作日志</a>
                </el-dropdown-item>
                <el-dropdown-item v-if="$commonJs._point('BI报表_全局配置_皮肤管理')" @click.native="setdialogTheme">
                  <a>皮肤管理</a>
                </el-dropdown-item>
                <el-dropdown-item v-if="$commonJs._point('BI报表_全局配置_计数单位')" @click.native="setdialogUnit">
                  <a>计数单位</a>
                </el-dropdown-item>
                <el-dropdown-item v-if="$commonJs._point('BI_F0047')" @click.native="dialogwatermark = true">
                  <a>页面水印</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <!-- <li><a href="http://mallcoobiimg.oss-cn-shanghai.aliyuncs.com/用户行为数据统计分析系统使用说明书.pdf" target="_blank">帮助文档</a></li> -->
          <!-- <li>English</li> -->
          <!-- <li v-if="['me'].includes(system)">
            <el-button size="mini" round class="switchbtn" @click.prevent="switchOld()">切换老版</el-button>
          </li> -->
          <li class="user">
            <el-dropdown trigger="click">
              <div class="el-dropdown-link dis_flex" v-if="basicInfo && basicInfo.user">
                <span>{{ basicInfo.user.name }}</span
                ><i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu class="thmemBottomMenu" slot="dropdown">
                <el-dropdown-item>
                  <a :href="changepwd">修改密码</a>
                </el-dropdown-item>
                <el-dropdown-item @click.native="signout">
                  <a>退出登录</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
        <el-button type="text" v-if="['crm', 'sale'].includes(system)" class="morbtn"
          ><a :href="dashboardUrl" target="_blank">更多数据<i class="el-icon-arrow-right"></i></a>
        </el-button>
      </div>
    </div>

    <el-dialog
      class="dialogTheme thmemBottomMenu"
      title="皮肤管理"
      :visible.sync="dialogTheme"
      width="500px"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="dialog-body">
        <div class="dialogitem">
          <label>主题皮肤：</label>
          <el-radio v-model="radioTheme" label="0">浅色主题</el-radio>
          <el-radio v-model="radioTheme" label="1">暗色主题</el-radio>
        </div>
        <div class="dialogitem">
          <label>高亮主色：</label>
          <div class="dialogitem-r">
            <el-radio v-model="radioMainColor" label="0">沉稳蓝</el-radio>
            <el-radio v-model="radioMainColor" label="1">活力橙</el-radio>
            <el-radio v-model="radioMainColor" label="2">明亮青</el-radio>
            <el-radio v-model="radioMainColor" label="3">浪漫红</el-radio>
            <el-radio v-model="radioMainColor" label="4">梦幻紫</el-radio>
          </div>
        </div>
        <div class="dialogitem">
          <label>同环比颜色：</label>
          <el-radio v-model="contrastColor" label="0">上升绿下降红</el-radio>
          <el-radio v-model="contrastColor" label="1">上升红下降绿</el-radio>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="dialogTheme = false">取 消</el-button>
        <el-button type="primary" size="small" @click="setTheme">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      class="dialogUnit thmemBottomMenu"
      title="计数单位"
      :visible.sync="dialogUnit"
      width="600px"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="dialog-body">
        <label>计数单位选择：</label>
        <div class="dialogitem">
          <el-radio v-model="radioUnit" label="chinese">中国标准（万、亿）</el-radio>
          <el-radio v-model="radioUnit" label="international">国际标准（K、M，其中K表示千，M表示百万）</el-radio>
          <el-radio v-model="radioUnit" label="none">不显示计数单位</el-radio>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="dialogUnit = false">取 消</el-button>
        <el-button type="primary" size="small" @click="setUnit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      class="dialogUnit thmemBottomMenu"
      title="页面水印"
      :visible.sync="dialogwatermark"
      width="600px"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="dialog-body">
        <div class="dialogitem dis_flex">
          <label>页面水印：</label>
          <div class="dialogitem-r dis_flex">
            <el-radio v-model="disableWatermark" :label="false">显示</el-radio>
            <el-radio v-model="disableWatermark" :label="true">不显示</el-radio>
          </div>
        </div>
        <div class="dialogitem dis_flex" v-if="!disableWatermark">
          <label>透明度：</label>
          <div class="dialogitem-r dis_flex">
            <el-slider v-model="watermarkOpacity" input-size="small" :min="10" :max="30" :show-tooltip="true" :format-tooltip="formatTooltip" />
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="dialogwatermark = false">取 消</el-button>
        <el-button type="primary" size="small" @click="setwatermark">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog class="dialogUser thmemBottomMenu" title="" :visible.sync="dialogUser" width="600px" @close="userClose" :append-to-body="true">
      <div class="dialog-body">
        <el-select v-model="modelUser" size="small" type="text" placeholder="请选择" @change="userSelectChange" popper-class="modelUserValue thmemBottomMenu">
          <el-option v-for="item in userSelects" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <div class="dialogitem">
          <el-input
            v-model="modelUserVal"
            @input="onInput"
            @keyup.enter.native="onEnter"
            size="small"
            prefix-icon="el-icon-search"
            placeholder="请输入关键字"
          ></el-input>
          <ul v-if="modelUserVal" v-loading="userFilterLoading">
            <li v-for="(l, lidx) in showList" :key="lidx">
              <a :href="baseUrl + analysisProxy + '/user/' + projectID + '?userID=' + l['_user_mid']" target="_blank"
                >{{ l.splits[0] }}<b>{{ modelUserVal }}</b
                >{{ l.splits[1] }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="dialogUser = false">取 消</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { putAxisunit, getHomeInfo, SetProjectTheme } from '@/api/apiV2_dashboard'
import { getUserRetrieval } from '@/api/apiV2_dashboard'
import { putWatermark } from '@/api/otherapi'
import NewsIcon from './newsIcon.vue'
import { removeToken } from '@/utils/auth'
import BI_config from '@/utils/config'
import InitUserly from '@/plugin/userly.js'
import watermark from '@/plugin/watermark.js'

export default {
  name: 'headerBox',
  props: ['headdata'],
  components: { NewsIcon },
  data() {
    return {
      basicdata: null,
      activeIndex: 0,
      theme: null,
      dialogTheme: false,
      dialogUnit: false,
      radioTheme: 0,
      radioUnit: 'chinese',
      radioMainColor: 0,
      newsIconkey: 1,
      contrastColor: '0',

      dialogwatermark: false,
      disableWatermark: true,
      watermarkOpacity: 12,

      modelUser: '_mobile',
      userSelects: [
        {
          label: '手机号',
          value: '_mobile',
        },
        {
          label: '会员卡号',
          value: '_membercard_id',
        },
        {
          label: '用户ID',
          value: '_uid',
        },
      ],
      modelUserVal: '',
      dialogUser: false,
      showList: [],
      timer: 0,
      userFilterLoading: false,

      baseUrl: BI_config.analysisUrl,
      analysisProxy: BI_config.analysisProxy,

      changepwd: BI_config.changePwd,
      bigmanageurl1: BI_config.bigmanage + BI_config.manageProxy + '/manage/permissions/users',
      bigmanageurl2: BI_config.bigmanage + BI_config.manageProxy + '/manage/permissions/roles',
      bigmanageurl3: BI_config.bigmanage + BI_config.manageProxy + '/manage/downCenter',
      bigmanageurl4: BI_config.bigmanage + '/MarketingPlan/NoticeList',
    }
  },
  computed: {
    ...mapState({
      maintInfo: state => state.maintInfo,
      basicInfo: state => state.basicInfo,
      projectID: state => state.projectID,
      dashboardUrl: state => BI_config.SiteHost + BI_config.dashboardProxy + '/Index/' + state.projectID,
      system: state => state.system,
      changePPTPages: state => state.changePPTPages,
      bigmanageurl5: state => BI_config.analysisUrl + BI_config.analysisProxy + '/bzlog/' + state.projectID,
      subscribeurl: state => BI_config.analysisUrl + BI_config.analysisProxy + '/subscribe/' + state.projectID,
      earlywarnurl: state => BI_config.analysisUrl + BI_config.analysisProxy + '/earlywarn/' + state.projectID,
    }),
  },
  created() {},
  methods: {
    switchOld() {
      let mallID = this.$route.params.mallID
      let host = location.host
      if (host.includes('-t')) {
        window.open('http://me.mp-t.mallcoo.cn/Loc/Overview/Index/' + mallID)
      } else if (host.includes('-p')) {
        window.open('http://me.mp-p.mallcoo.cn/Loc/Overview/Index/' + mallID)
      } else {
        window.open('http://me.mp.mallcoo.cn/Loc/Overview/Index/' + mallID)
      }
    },

    openLeft() {
      this.$set(this.headdata, 'closeLeft', !this.headdata.closeLeft)
      this.$emit('getInfo', this.headdata)
    },
    getTheme() {
      //获取系统颜色
      const project = this.basicdata.project
      this.radioTheme = project.theme.toString()
      this.radioMainColor = project.mainColor.toString()
      const contrastTrendColor = project.contrastTrendColor || 0
      this.contrastColor = contrastTrendColor.toString()

      this.radioUnit = project.axisUnit

      this.$pageunit = this.radioUnit

      let themeColor = project.theme > 0 ? 'dark' : 'light'
      let mainColor = 'lan'
      switch (project.mainColor) {
        case 0:
          mainColor = 'lan'
          break
        case 1:
          mainColor = 'cheng'
          break
        case 2:
          mainColor = 'qing'
          break
        case 3:
          mainColor = 'hong'
          break
        case 4:
          mainColor = 'zi'
          break
      }
      window.document.documentElement.setAttribute('data-theme', themeColor)
      window.document.documentElement.setAttribute('data-high', mainColor)
      this.newsIconkey++
    },
    setTheme() {
      let theme = {
        theme: this.radioTheme,
        mainColor: this.radioMainColor,
        contrastTrendColor: this.contrastColor,
      }
      SetProjectTheme(theme)
      setTimeout(() => {
        this.GetProject()
      }, 200)
      this.dialogTheme = false
    },
    setUnit() {
      let axisunit = {
        axisUnit: this.radioUnit,
      }
      putAxisunit(axisunit)
      setTimeout(() => {
        this.GetProject()
      }, 200)
      this.dialogUnit = false
    },
    async setwatermark() {
      const param = {
        disableWatermark: this.disableWatermark,
        opacity: this.watermarkOpacity,
      }
      await putWatermark(param)
      this.GetProject()
      this.dialogwatermark = false
    },
    setdialogTheme() {
      this.dialogTheme = true
    },
    setdialogUnit() {
      this.dialogUnit = true
    },
    signout() {
      removeToken()
      location.reload()
    },
    GetProject() {
      let param = {
        projectID: this.projectID,
      }
      getHomeInfo().then(v => {
        this.basicdata = v.data
        this.radioUnit = v.data.project.axisUnit
        this.$store.commit('setbasicInfo', v.data)
        this.$store.commit('setPageunit', this.radioUnit)
        this.$pageunit = this.radioUnit
        this.getTheme()
        if (v && !(v.data && v.data.project.disableWatermark)) {
          watermark.set(v.data.user.name, 12, v.data.project.theme)
        }
      })
    },
    projectlisturl: function() {
      let path = this.system == 'me' ? 'me' : 'list'
      return `${BI_config.SiteHost}${BI_config.dashboardProxy}/${path}`
    },

    // 跳转个人详情
    searchUser() {
      this.dialogUser = true
    },
    openHelp() {
      window.open('https://mp.mallcoo.cn/a/mdocs/Support/Detail?docType=2&docID=247')
    },
    onInput() {
      let that = this
      this.userFilterLoading = true
      clearTimeout(that.timer)
      that.timer = setTimeout(function() {
        that.getUserList()
      }, 1000)
    },
    onEnter() {
      clearTimeout(this.timer)
    },
    async getUserList() {
      let param = {
        code: this.modelUser,
        fuzzy: this.modelUserVal,
      }
      let retrievalRes = await getUserRetrieval(param)
      let wordsMode = this.modelUser
      this.showList = retrievalRes.data.map(x => {
        let keyValIndex = x[wordsMode].indexOf(this.modelUserVal)
        x.splits = []
        x.splits[0] = x[wordsMode].substring(0, keyValIndex)
        x.splits[1] = x[wordsMode].substring(keyValIndex + this.modelUserVal.length)
        return x
      })

      this.userFilterLoading = false
    },
    userClose() {
      clearTimeout(this.timer)
      this.modelUser = '_mobile'
      this.modelUserVal = ''
      this.showList = []

      this.userFilterLoading = false
    },
    userSelectChange() {
      clearTimeout(this.timer)
      this.modelUserVal = ''
      this.showList = []
      this.userFilterLoading = false
    },
    initHight() {
      let pathName = this.$route.meta.name
      if (this.headdata.menus) {
        this.headdata.menus.forEach((x, i) => {
          if (x.code === pathName) {
            this.activeIndex = i
          }
        })
      }
    },
    menuhref(href) {
      // 如果当前页面是 ppt 页面 判断是否有改变
      if (this.$route.name === 'reportPages') {
        if (this.changePPTPages.length > 0) {
          this.$confirm('有未保存的页面，确定离开?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false,
            customClass: 'theme_message',
          })
            .then(() => {
              window.location.href = href
            })
            .catch(() => {})
        } else {
          window.location.href = href
        }
      } else {
        window.location.href = href
      }
    },
    formatTooltip(number) {
      return number + '%'
    },
  },
  mounted() {
    this.basicdata = this.basicInfo
    this.initHight()
    this.getTheme()

    this.disableWatermark = this.basicdata.project.disableWatermark
    this.watermarkOpacity = this.basicdata.project.watermarkOpacity
    const user = this.basicInfo && this.basicInfo.user
    if (user) {
      InitUserly({ mallId: '', visitorId: user.id, userName: user.name, userType: user.identityType, mallName: '' })
    }
  },
}
</script>

<style lang="scss" scoped>
.headerBox {
  .switchbtn {
    color: #409eff;
  }
  @include theme_color2($theme-light);
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.12);
  .logo {
    width: 250px;
    height: 60px;
    transition: 0.3s ease-in-out;
    @include high_bg1($highcolor-cheng);
    a {
      width: 100%;
      height: 100%;
      img {
        width: 133px;
      }
    }
  }
  .logo-r {
    width: calc(100% - 250px);
    padding: 0 15px;
    transition: 0.3s ease-in-out;
  }
  .name {
    font-size: 20px;
    span {
      margin-right: 10px;
      margin-top: 4px;
      cursor: pointer;
    }
    h3 {
      font-weight: bold;
      font-size: 20px;
      span {
        font-weight: normal;
        i {
          @include sec_color($sec-light);
        }
      }
    }
  }
  .nav {
    flex: 1;
    height: 100%;
    margin-left: 1%;
    text-align: left;
    .el-menu {
      width: 100%;
      background: none;
      border: none;
      display: flex;
      overflow: hidden;
      .el-menu-item {
        padding: 0 2%;
        span {
          padding: 0 10px;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .el-menu-custom {
      vertical-align: top;
      > li {
        > .el-submenu__title {
          font-size: 16px;
        }
      }
    }
    ul {
      li {
        font-size: 16px;
      }
    }
  }
  .head-r {
    ul {
      li {
        margin: 0 10px;
        font-size: 14px;
        cursor: pointer;
        &.user {
          img {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background: #a6dbff;
            margin-right: 5px;
          }
          .el-dropdown-link {
            span {
              display: inline-block;
              max-width: 110px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .el-dropdown {
          @include theme_color2($theme-light);
        }
        > a {
          @include theme_color2($theme-light);
        }
        .iconfont {
          font-size: 18px;
        }
      }
    }
    .morbtn {
      a {
        @include high_color1($highcolor-cheng);
      }
    }
  }
}

.dialogTheme {
  .el-dialog {
    .dialog-body {
      .dialogitem {
        text-align: left;
        margin-bottom: 20px;
        padding-left: 50px;
        line-height: 24px;
        display: flex;
        .el-radio {
          line-height: 24px;
        }
        .dialogitem-r {
          display: flex;
          flex-wrap: wrap;
          .el-radio {
            flex: 0 0 33%;
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.dialogUnit {
  .el-dialog {
    .dialog-body {
      overflow: hidden;
      margin-bottom: 40px;

      .dialogitem {
        > label {
          float: left;
          width: 100px;
          text-align: right;
          margin-left: 50px;
          line-height: 24px;
        }
      }
      .dialogitem-r {
        float: left;
        width: calc(100% - 200px);
        margin-left: 10px;
        text-align: left;
        .el-radio {
          display: block;
          line-height: 24px;
          margin-bottom: 10px;
        }
        .el-slider {
          width: 200px;
        }
      }
    }
  }
}
.changespace {
  .wenhao {
    margin-right: 3px !important;
  }
  display: flex;
  align-items: center;
  span:first-child {
    display: block;
    margin-left: 10px;
    margin-right: 0px;
    line-height: 30px;
  }
  a {
    margin-top: 12px !important;
    margin-left: 3px;
  }
}
</style>

<style lang="scss">
.el-menu-default {
  .el-menu-item {
    background: none !important;
    border-bottom: none !important;
    @include theme_color2($theme-light);
    &:not(.is-disabled):focus,
    &:not(.is-disabled):hover {
      @include high_color1($highcolor-cheng);
    }
    &.selected {
      @include high_color1($highcolor-cheng);
      &:after {
        display: block;
        content: '';
        width: 42px;
        height: 6px;
        border-radius: 3px;
        @include high_bg1($highcolor-cheng);
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -21px;
      }
    }
  }
}
.el-menu.el-menu-custom {
  .el-submenu {
    background: none !important;
    border-bottom: none !important;
    .el-submenu__title {
      font-size: 16px;
      background: none !important;
      border-bottom: none !important;
      @include theme_color2($theme-light);
      &:focus,
      &:hover {
        // @include high_color1($highcolor-cheng);
      }
    }
    &.is-active,
    &:hover {
      .el-submenu__title {
        // @include high_color1($highcolor-cheng);
      }
    }
  }
}

.thmemCustomMenu {
  .el-menu {
    @include theme_bg1($theme-light);
    @include theme_border_color($theme-border-light);
    li {
      background: none !important;
      @include theme_color2($theme-light);
      &.is-active {
        // @include theme_color2($theme-light);
      }
      &:hover,
      &:focus {
        background: none !important;
        @include high_color1($theme-light);
      }
    }
  }
}

.dialogUser {
  .el-dialog {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 40px 0 10px;
      border: none !important;
    }
    .dialog-body {
      overflow: hidden;
      padding: 0 60px;
      margin-bottom: 30px;
      .el-select {
        float: left;
        width: 120px;
        margin-right: 10px;
        .el-input {
          .el-input__inner {
            border: none !important;
            font-weight: bold;
            text-align: right;
          }
        }
      }
      .dialogitem {
        float: left;
        width: calc(100% - 130px);
        ul {
          min-height: 80px;
          max-height: 200px;
          overflow: auto;
          @include theme_border_color($theme-border-light);
          border-top: 0 !important;
          li {
            line-height: 24px;
            a {
              display: block;
              @include theme_color2($theme-light);
              padding-left: 30px;
            }
            &:hover {
              background: #ccc;
            }
            b {
              @include high_color1($highcolor-cheng);
              font-weight: normal;
            }
          }
        }
        .el-loading-mask {
          @include theme_bg1($theme-light);
          opacity: 0.9;
        }
      }
    }
  }
}
.modelUserValue {
  .popper__arrow {
    right: 30px !important;
  }
}
.project_info {
  margin-top: 10px;
}
.project_info_status {
  padding-bottom: 6px;
}
</style>
